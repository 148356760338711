import React from "react";
import { cn } from "../utils/classNames";
import LinkButton from "../components/Button";
import { useInView } from "framer-motion";
import LinkCard from "../components/Card/LinkCard";
import Section from "../components/Section";
import { APP_URL } from "../constants";

const Token = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });
  const linkCardContent = [
    {
      title: "Token flywheel",
      text: "Buyback, Burn & Distribute",
      href: "/",
      img: "/image/linkCardImage1.png",
    },
    {
      title: "Staking Perks",
      text: "Get Access to exclusive perks",
      href: `${APP_URL}/stake`,
      img: "/image/linkCardImage2.png",
    },
    {
      title: "Learn more",
      text: "Learn about $STFX in our Docs",
      href: "/",
      img: "/image/linkCardImage.svg",
    },
  ];
  return (
    <Section
      className="h-fix md:px-[144px] flex-col md:py-[120px] py-[64px]"
      id="token"
    >
      <div className="flex flex-col max-w-[426px] gap-[18px] mx-auto items-center">
        <span
          className={cn(
            "inline-block bg-titleGradient text-transparent bg-clip-text font-normal",
            "text-base lg:text-[22px] mb-1 pb-1"
          )}
        >
          Powering the Protocol
        </span>
        <span className="text-[32px] lg:text-[64px] text-white font-medium mb-6">
          $STFX Token
        </span>
        <span className="text-sm lg:text-lg text-white/70 flex text-center">
          Get Access to
        </span>
        <div className="flex gap-5 justify-center">
          <LinkButton
            className={
              "bg-green backdrop-blur-lg w-[150px] text-black  items-center justify-center hover:bg-green/80"
            }
            link="https://app.uniswap.org/#/swap?chain=homestead&inputCurrency=ETH&outputCurrency=0x9343e24716659A3551eB10Aff9472A2dcAD5Db2d"
          >
            Buy $STFX
          </LinkButton>
          <LinkButton
            className={
              "bg-white/5 backdrop-blur-lg text-white w-[150px] justify-center items-center hover:bg-white/10"
            }
            link={`${APP_URL}/stake`}
          >
            Stake $STFX
          </LinkButton>
        </div>
      </div>
      <div className="w-full relative items-center justify-end h-[300px] flex flex-col overflow-hidden">
        <img
          src="/logo/stfx-coin.png"
          className={cn(
            "max-w-[490px] h-full absolute transition-all duration-1000 delay-100",
            isInView ? "-bottom-1/3" : "-bottom-[100%]"
          )}
          alt="stfx-coin"
        />

        <div
          className="flex w-full h-0.5 bg-tokenLineBgGradient relative z-20"
          ref={ref}
        ></div>
      </div>
      {/* link cards */}
      <div className="flex gap-3 mt-6 max-h-[368px] flex-col lg:flex-row">
        {linkCardContent.map((item, index) => (
          <LinkCard
            text={item.text}
            href={item.href}
            title={item.title}
            img={item.img}
          />
        ))}
      </div>
    </Section>
  );
};

export default Token;
