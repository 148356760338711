import React from "react";
import Carousel from "../components/Carousel";
import CarouselImage from "../components/Carousel/CarouselImage";
import Section from "../components/Section";

const Sponsor = () => {
  const carouselContent = [
    {
      image: "/image/sponsor/cl207.png",
      title: "CL207",
    },
    {
      image: "/image/sponsor/theCryptoDog.png",
      title: "TheCryptoDog",
    },
    {
      image: "/image/sponsor/moonOverlord.png",
      title: "Moon Overlord",
    },
    {
      image: "/image/sponsor/cryptoPathic.png",
      title: "Crypto Pathic",
    },
    {
      image: "/image/sponsor/jasonFromTg.png",
      title: "JasonFromTg",
    },
    {
      image: "/image/sponsor/nicCarter.png",
      title: "Nic Carter",
    },
    {
      image: "/image/sponsor/willClemente.png",
      title: "Will Clemente",
    },
    {
      image: "/image/sponsor/pomp.png",
      title: "Pomp",
    },
    {
      image: "/image/sponsor/byzantineGeneral.png",
      title: "Byzantine General",
    },
    {
      image: "/image/sponsor/voyBoy.png",
      title: "VoyBoy",
    },
    {
      image: "/image/sponsor/misirMahmudov.png",
      title: "Misir Mahmudov",
    },
    {
      image: "/image/sponsor/muradMahmudov.png",
      title: "Murad Mahmudov",
    },
    {
      image: "/image/sponsor/permabullNino.png",
      title: "Permabull Nino",
    },
    {
      image: "/image/sponsor/checkmate.png",
      title: "Checkmate",
    },
    {
      image: "/image/sponsor/davidIach.png",
      title: "David Iach",
    },
    {
      image: "/image/sponsor/limzero.png",
      title: "Limzero",
    },
    {
      image: "/image/sponsor/cryptomer.png",
      title: "Cryptomer",
    },
    {
      image: "/image/sponsor/michelleSun.png",
      title: "Michelle Sun",
    },
    {
      image: "/image/sponsor/binji.png",
      title: "Binji",
    },
    {
      image: "/image/sponsor/pango.png",
      title: "Pango",
    },
    {
      image: "/image/sponsor/rileyGMI.png",
      title: "RileyGMI",
    },
    {
      image: "/image/sponsor/wise_eth.png",
      title: "Wise_eth",
    },
    {
      image: "/image/sponsor/0xd54.png",
      title: "0xd54",
    },
    {
      image: "/image/sponsor/danAvax.png",
      title: "dan.avax",
    },
    {
      image: "/image/sponsor/fedSurfer.png",
      title: "Fed Surfer",
    },
    {
      image: "/image/sponsor/0xGOC.png",
      title: "0xGOC",
    },
    {
      image: "/image/sponsor/davyJones.png",
      title: "Davy Jones",
    },
    {
      image: "/image/sponsor/0xAlbus.png",
      title: "0xAlbus",
    },
    {
      image: "/image/sponsor/debussy.png",
      title: "Debussy",
    },
    {
      image: "/image/sponsor/nooNe0x.png",
      title: "NooNe0x",
    },
    {
      image: "/image/sponsor/axuETH.png",
      title: "AxuETH",
    },
    {
      image: "/image/sponsor/a_rhythmic.png",
      title: "a_rhythmic",
    },
  ];

  const sponsorCaroselContent = (() =>
    carouselContent.map((item, index) => (
      <CarouselImage key={index} image={item.image} title={item.title} />
    )))();
  return (
    <Section
      className="min-h-fit flex-col mt-10 mb-14 overflow-hidden mx-auto w-fit max-w-full"
      id="sponsors"
    >
      <span className="text-base lg:text-2xl font-normal text-white text-center">
        Backed & Supported by
      </span>
      {/* carosel */}
      <span className="relative z-30 flex">
        <Carousel content={sponsorCaroselContent} />
      </span>
    </Section>
  );
};

export default Sponsor;
