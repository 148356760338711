import React from "react";
import Card from "../components/Card";
import { cn } from "../utils/classNames";
import { useInView } from "framer-motion";
import Section from "../components/Section";

const SocialFinance = () => {
  const bottomSectionStyle = "bg-cardBgGradient px-9 py-8";
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  const cardContent = [
    {
      title: "On-chain Reputation",
      text: "Become a trader on STFX and start building your on-chain reputation. Get access to trading capital from thousands of investors.",
      img: "/image/onChainReputation.png",
    },
    {
      title: "Outsource your Trading",
      text: "Let the best on-chain traders trade for you - from ideation to execution!",
      img: "/image/outsourceYourTrading.png",
    },
    {
      title: "Earn more",
      text: "Get subscribers, trade with greater capital and earn 20% revenue share.",
      img: "/image/earnMore.png",
    },
    {
      title: "Social",
      text: "Subscribe to traders and get access to exclusive Chatrooms. Trade inside your own group chat in real time with your subscribers.OnChain reputation is the nonplus ultra, immutable and cannot be faked",
      img: "/image/social.png",
    },
  ];
  return (
    <Section
      className="bg-socialFinanceGradient rounded-tl-[80px] min-h-fit flex-col rounded-tr-[80px] pb-6"
      id="social-finance"
    >
      <div className="flex flex-col max-w-[564px] mx-auto items-center mt-16  mb-[72px]">
        <span
          className={cn(
            "inline-block bg-titleGradient text-transparent bg-clip-text font-normal",
            "text-base lg:text-[22px] mb-2"
          )}
        >
          Social Trading
        </span>
        <span className="text-[32px] lg:text-[64px] text-white font-medium mb-6">
          Social Finance
        </span>
        <span className="text-sm lg:text-lg text-white/70 flex text-center">
          STFX unites Social and Trading. Trade and earn together with your
          friends and followers.
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mx-8" ref={ref}>
        {cardContent.map((item, index) => (
          <Card
            bottomSectionStyle={bottomSectionStyle}
            title={item.title}
            text={item.text}
            image={item.img}
            className="max-w-[476px]"
            inView={isInView}
            key={`${item.text}-${index}`}
          />
        ))}
      </div>
    </Section>
  );
};

export default SocialFinance;
