import React from "react";
import { cn } from "../../utils/classNames";

interface CardProps {
  image?: string;
  title: string;
  text: string;
  bottomSectionStyle?: string;
  className?: string;
  inView: boolean;
}

const Card = ({
  image,
  title,
  text,
  bottomSectionStyle,
  className,
  inView,
}: CardProps) => {
  return (
    <div
      style={{
        transform: inView ? "scale(1)" : "scale(0)",
        opacity: inView ? 1 : 0,
        transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1)`,
      }}
      className={cn(
        "flex flex-1 rounded-[32px] overflow-hidden flex-col border border-solid border-white/5",
        className
      )}
    >
      {image && <img src={image} className="w-full h-full" alt={title} />}
      <div className={cn("flex flex-col gap-[18px]", bottomSectionStyle)}>
        <div className="text-white font-medium text-xl lg:text-2xl">
          {title}
        </div>
        <div className="text-white/50 text-sm font-normal lg:text-base">
          {text}
        </div>
      </div>
    </div>
  );
};

export default Card;
