import React from "react";

interface CarouselImageProps {
  image: string;
  title: string;
}

const CarouselImage = ({ image, title }: CarouselImageProps) => {
  return (
    <div className="flex flex-col gap-2 items-center">
      <img src={image} alt={title} className="h-11 w-fit" />
      <span className="text-xs text-white/50 font-light">{title}</span>
    </div>
  );
};

export default CarouselImage;
