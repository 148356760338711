import React from "react";
import LinkButton from "../Button";
import { APP_URL } from "../../constants";

export const LaunchAppButton = () => {
  return (
    <LinkButton
      className={"text-black flex items-center bg-green hover:bg-green/80"}
      link={APP_URL}
    >
      <img
        src="/icons/launchAppArrow.svg"
        alt="launch-app-arrow"
        className="lg:block hidden"
        width={20}
        height={20}
      />
      Launch App
    </LinkButton>
  );
};
