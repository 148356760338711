// import React from "react";
// import { ManagerWithStatsSubscribe } from "../interface";
// import { fetchTopManagers } from "../requests";
// import { useQuery } from "react-query";
import About from "./About";
import EnterApp from "./EnterApp";
import Footer from "./Footer";
import Home from "./Home";
// import PrizePool from "./PrizePool";
import SocialFinance from "./SocialFinance";
import Sponsor from "./Sponsor";
// import Stats from "./Stats";
// import Subscriptions from "./Subscriptions";
import Token from "./Token";

const AppWrapper = () => {
  // const { data: topManagers, isLoading: isTopManagersLoading } = useQuery({
  //   staleTime: 0,
  //   cacheTime: 0,
  //   queryKey: ["topManagers"],
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: true,
  //   queryFn: async (): Promise<ManagerWithStatsSubscribe[]> => {
  //     const managers: ManagerWithStatsSubscribe[] = await fetchTopManagers();
  //     return managers.filter((item, index) => index < 3);
  //   },
  // });
  return (
    <>
      {/* section 1:home */}
      <Home />
      <Sponsor />
      <SocialFinance />
      <Token />
      {/* <PrizePool /> */}
      <About />
      {/* <Stats
        topManagers={topManagers}
        isTopManagersLoading={isTopManagersLoading}
      /> */}
      {/* <Subscriptions
        topManagers={topManagers}
        isTopManagersLoading={isTopManagersLoading}
      /> */}
      <EnterApp />
      <Footer />
    </>
  );
};

export default AppWrapper;
