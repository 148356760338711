import React from "react";

const Carousel = ({ content }: { content: React.ReactNode }) => {
  return (
    <div className="relative flex overflow-x-hidden gap-14">
      <div className="py-12 flex flex-1 gap-14 animate-carousel whitespace-nowrap">
        {content}
      </div>
      <div className="absolute flex top-0 flex-1 gap-14 py-12 animate-carousel2 whitespace-nowrap mx-14">
        {content}
      </div>
    </div>
  );
};

export default Carousel;
