import React from "react";
import { APP_URL } from "../constants";

const Footer = () => {
  const socialMediaLinks = [
    {
      title: "twitter",
      img: "/icons/twitterSocial.svg",
      href: "https://twitter.com/STFX_IO",
    },
    {
      title: "discord",
      img: "/icons/discordSocial.svg",
      href: "https://discord.gg/stfx",
    },
    {
      title: "telegram",
      img: "/icons/telegramSocial.svg",
      href: "https://t.me/stfx",
    },
    {
      title: "youtube",
      img: "/icons/youtubeSocial.svg",
      href: "https://www.youtube.com/@stfx_io",
    },
    {
      title: "medium",
      img: "/icons/mediumSocial.svg",
      href: "https://medium.com/@STFX_IO",
    },
  ];

  const linkClassName = "text-white/50 font-normal text-lg hover:text-white/60";
  return (
    <div className="flex w-full h-fit border-t border-solid p-14 border-white/20 items-start justify-center">
      <div className="flex w-full max-w-[1112px] flex-col lg:flex-row justify-between items-center">
        <div className="flex flex-col gap-6">
          <a href="/">
            <img src="/logo/stfx-logo.svg" width={124} height={24} alt="STFX" />
          </a>
          <span className="text-white/50 text-lg">
            The Social Trading Protocol
          </span>
          <span className="flex gap-8">
            {socialMediaLinks.map((item, index) => (
              <a
                href={item.href}
                target="_blank"
                key={`${index}-${item.title}`}
                rel="noreferrer"
              >
                <img src={item.img} width={20} height={20} alt={item.title} />
              </a>
            ))}
          </span>
          <span className="text-white/10 text-lg">© 2024 STFX</span>
        </div>
        <div className="flex gap-12 lg:gap-24">
          <div className="flex flex-col gap-3">
            <span className="text-white text-lg mb-2">About</span>
            <a href="/" className={linkClassName}>
              Social Trading
            </a>
            <a href="/" className={linkClassName}>
              $STFX Token
            </a>
            <a href="/" className={linkClassName}>
              Battle Royale
            </a>
            <a href="/" className={linkClassName}>
              About STFX
            </a>
            <a href="/" className={linkClassName}>
              Subscriptions
            </a>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-white text-lg mb-2">Links</span>
            <a
              href={APP_URL}
              target="_blank"
              className={linkClassName}
              rel="noreferrer"
            >
              App
            </a>
            <a
              href="https://docs.stfx.io/"
              target="_blank"
              className={linkClassName}
              rel="noreferrer"
            >
              Docs
            </a>
            <a
              href={`${APP_URL}/stats`}
              target="_blank"
              className={linkClassName}
              rel="noreferrer"
            >
              Stats
            </a>
            <a
              href="https://gov.stfx.io"
              target="_blank"
              className={linkClassName}
              rel="noreferrer"
            >
              Discourse
            </a>
            <a
              href="https://vote.stfx.io"
              target="_blank"
              className={linkClassName}
              rel="noreferrer"
            >
              Snapshot
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
