import Navbar from "./components/Navbar";
import { QueryClient, QueryClientProvider } from "react-query";
import AppWrapper from "./container";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="min-h-screen relative mx-auto max-w-[1820px] flex flex-col">
        <div className="fixed top-0 z-50 w-full left-0">
          <Navbar />
        </div>

        <AppWrapper />
      </div>
    </QueryClientProvider>
  );
}

export default App;
