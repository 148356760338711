import React from "react";
import { cn } from "../../utils/classNames";

interface SectionProps {
  children: React.ReactNode;
  className?: string;
  id: string;
}

const Section = ({ children, className, id }: SectionProps) => {
  return (
    <div
      className={cn(
        "flex relative w-full min-h-screen justify-center items-center font-lexend",
        className
      )}
      id={id}
    >
      {children}
    </div>
  );
};

export default Section;
