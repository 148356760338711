import React from "react";
import { cn } from "../../utils/classNames";

interface LinkButtonProps {
  children: React.ReactNode;
  className?: String;
  link?: string;
}

const LinkButton = ({ children, className, link }: LinkButtonProps) => {
  return (
    <a
      href={link}
      target="_blank"
      className="decoration-transparent"
      rel="noreferrer"
    >
      <span
        className={cn(
          "flex font-semibold h-10 decoration-transparent px-4 gap-2 py-2 border border-solid border-white/15 rounded-[10px]",
          className
        )}
      >
        {children}
      </span>
    </a>
  );
};

export default LinkButton;
