import React from "react";
import { cn } from "../utils/classNames";
import { useInView } from "framer-motion";
import Card from "../components/Card";
import LinkButton from "../components/Button";
import { motion } from "framer-motion";
import Section from "../components/Section";
import { APP_URL } from "../constants";

const About = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });
  const tradingCardData = [
    {
      title: "Exclusive Chats",
      text: "Join exclusive chatrooms and automatically join the trades of the top traders.",
      className: "sm:h-[415px]",
      image: "/image/social1.png",
    },
    {
      title: "PWA Mobile App",
      text: "Frictionless Mobile experience using Progressive web app technology. Full access to all STFX functionality including Trading, Investing, Chats and more.",
      className: "lg:col-span-2 sm:h-[415px] ",
      image: "/image/social2.png",
    },
    {
      title: "Single Trade Vaults",
      text: "One Trade equals one Vault. Isolated risk compared to Portfolio based alternatives.",
      className: "lg:col-span-2 sm:h-[457px]",
      image: "/image/social3.png",
    },
    {
      title: "Build your Reputation",
      text: "Get featured on our Onchain leaderboard",
      className: "sm:h-[457px]",
      image: "/image/social4.png",
    },
    {
      title: "DDEX aggregator",
      text: "Finding the best route for your trades across multiple DDEXs like GMX, Hyperliquid and Vertex",
      className: "sm:h-[457px]",
      image: "/image/social5.png",
    },
    {
      title: "Subscriptions",
      text: "Subscribe to traders and automatically join all of their trades. As a trader, get access to instant liquidity and trade with greater size.",
      className: "sm:h-[457px]",
      image: "/image/social6.png",
    },
    {
      title: "Staking Perks",
      text: "Exclusive Perks for $STFX Stakers including greater fee discounts, greater profit share, greater trade size, longer trade durations and much more.",
      className: "sm:h-[457px]",
      image: "/image/social7.png",
    },
  ];

  return (
    <Section
      className="h-fit flex-col items-center justify-center px-8 lg:px-20 mb-16"
      id="about"
    >
      <div className="flex flex-col max-w-[564px] mx-auto items-center mt-16  mb-[72px]">
        <span
          className={cn(
            "inline-block bg-titleGradient text-transparent bg-clip-text font-normal",
            "text-base lg:text-[22px] mb-2"
          )}
        >
          Social Trading
        </span>
        <span className="text-[32px] lg:text-[64px] text-white font-medium mb-6">
          Social Trading
        </span>
        <span className="text-sm lg:text-lg text-white/70 flex text-center">
          STFX unites Social and Trading. Trade and earn together with your
          friends and followers.
        </span>
      </div>
      <div
        className={cn(
          "grid grid-cols-1 lg:grid-cols-3 gap-3 w-full max-w-[1110px] mb-16"
        )}
        ref={ref}
      >
        {tradingCardData.map((item, index) => (
          <motion.div
            whileHover={{ scale: 0.98 }}
            transition={{ duration: 0.5, yoyo: Infinity }}
            className={item.className}
          >
            <Card
              title={item.title}
              bottomSectionStyle="absolute bottom-0 p-5 lg:p-10 gap-2.5"
              text={item.text}
              className={cn("bg-white/5 h-[264px]", item.className)}
              inView={isInView}
              image={item.image}
              key={`${item.text}-${index}`}
            />
          </motion.div>
        ))}
      </div>
      <div className="flex gap-5 justify-center">
        <LinkButton
          className={
            "bg-green backdrop-blur-lg w-[150px] text-black justify-center hover:bg-green/80"
          }
          link={`${APP_URL}/trade`}
        >
          Trade
        </LinkButton>
        <LinkButton
          className={
            "bg-white/5 backdrop-blur-lg text-white w-[150px] justify-center hover:bg-white/10"
          }
          link={`${APP_URL}/subscribe`}
        >
          Subscribe
        </LinkButton>
      </div>
    </Section>
  );
};

export default About;
