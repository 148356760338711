import React from "react";
import { cn } from "../../utils/classNames";
import Atropos from "atropos/react";

interface LinkCardProps {
  className?: string;
  href?: string;
  title: string;
  text?: string;
  img?: string;
  textStyle?: string;
}

const LinkCard = ({
  className,
  href,
  title,
  text,
  img,
  textStyle,
}: LinkCardProps) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <Atropos className="rounded-[36px]">
        <div
          className={cn(
            "border border-solid border-white/5 rounded-[36px] p-8 flex flex-col gap-8 bg-cardBgGradient",
            className
          )}
        >
          <span>
            <span className="flex w-full justify-between items-center mb-[14px]">
              <span className="text-white text-xl lg:text-[26px] font-medium">
                {title}
              </span>
              <img src="/icons/arrow_outward.svg" alt="arrow" />
            </span>
            {text && (
              <span
                className={cn("text-white/50 text-sm lg:text-lg", textStyle)}
              >
                {text}
              </span>
            )}
          </span>
          {img && (
            <span className="w-full items-center justify-center h-full  max-h-[203px] md:flex hidden">
              <img src={img} alt="link-card" data-atropos-offset="10" />
            </span>
          )}
        </div>
      </Atropos>
    </a>
  );
};

export default LinkCard;
